import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import MasterRoute from './routes/MasterRoute';
import { Provider } from 'react-redux'
import  store  from './redux/store';
import TagManager from 'react-gtm-module'

import "../src/i18n";

import './index.css'


 
 
const tagManagerArgs = {
    gtmId: 'GTM-NH988X7'
}
 
TagManager.initialize(tagManagerArgs)




const root = ReactDOM.createRoot(document.getElementById('root'));




root.render(
  <Provider store={store}>

  <BrowserRouter>

  <MasterRoute/>
</BrowserRouter>
</Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
