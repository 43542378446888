import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import createCache from "@emotion/cache";
import { useTranslation } from 'react-i18next';
import rtlPlugin from "stylis-plugin-rtl";
import { create } from "jss";
import { jssPreset } from '@mui/styles';
import rtl from "jss-rtl";
import { WebsiteTheme } from './Theme';
import { Box } from '@mui/system';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react'
import { StylesProvider } from '@mui/styles'
import NavBar from './NavBar';
import { Avatar, Button, Card, CardContent, Grid, TextField, Typography, useMediaQuery, CssBaseline, FormGroup, Alert, CircularProgress, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import CalculateIcon from '@mui/icons-material/Calculate';
import gojoImage from './image/gojo.jpg'
import facebookicon from './image/facebook.png'
import CallUs from './image/CallUs.png'
import { Link } from 'react-router-dom';










function WebSite() {



    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });







    const { i18n } = useTranslation();
    document.dir = i18n.dir();
    const theme = WebsiteTheme(i18n.dir());

    const cacheRtl = createCache({
        key: theme.direction === "rtl" ? "muirtl" : "muiltrz",
        prepend: true,
        stylisPlugins: theme.direction === "rtl" ? [prefixer, rtlPlugin] : [prefixer],

    });







    return (


        <CacheProvider value={cacheRtl}>

            <StylesProvider jss={jss}>
                <Box component="section">

                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Body />

                    </ThemeProvider>
                </Box>

            </StylesProvider >

        </CacheProvider >

    )
}




const Body = () => {





    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box className="box1" sx={{ width: { lg: "55%" }, height: "100vh" }}>
                <img alt="" style={{ width: "100%", height: "100%" }} src={gojoImage} />

                <Box
                    className="box2"
                    sx={{
                        position: "absolute",
                        top: "30%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { lg: "40%", md: "70%", sm: "95%", xs: "95%" }, // Add this line to make box2 width same as box1
                        maxWidth: "100%" // Add this line to limit the width of box2 to the width of box1
                        , background: "rgba(201, 199, 199, 0.77)",
                        padding: 2

                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                        <a href="https://web.facebook.com/gojoshipping" onClick={() => window.dataLayer.push({"event":"facebok_click"})} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>
                            <Avatar alt="Remy Sharp" src={facebookicon} sx={{ width: { lg: 150, xs: 100 }, height: { lg: 150, xs: 100 } }} />
                            <Typography variant='h2' color="white"> صفحتنا على فيس بوك</Typography>

                        </a>




                        <a href="tel:+962-79-650-66-26"  onClick={() => window.dataLayer.push({"event":"facebok_click"})} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 2 }}>
                            <Avatar alt="Remy Sharp" src={CallUs} sx={{ width: { lg: 150, xs: 100 }, height: { lg: 150, xs: 100 } }} />
                            <Typography variant='h2' color="white"> اتصل بنا</Typography>

                        </a>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};






export default WebSite