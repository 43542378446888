import { createTheme } from '@mui/material/styles';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import sx from '@mui/system/sx';

import ArabicBlackWoff from '.././assets/fonts/Arabic/ArabicBlack.woff';
import ArabicBlackTtf from '.././assets/fonts/Arabic/ArabicBlack.ttf';
import ArabicRegualrWoff from '.././assets/fonts/Arabic/ArabicRegualr.woff';
import ArabicRegualrTtf from '.././assets/fonts/Arabic/ArabicRegualr.ttf';
import ArabicBoldTtf from '.././assets/fonts/Arabic/ArabicBold.ttf';
import ArabicBoldWoff from '.././assets/fonts/Arabic/ArabicBold.woff';
import { fontSize } from '@mui/system';



export const LoginTheme = createTheme({


    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {

                    background: "linear-gradient(12deg, rgba(67,100,175,1) 0%, rgba(79,171,217,1) 100%) !important",
                    width: '100%',
                    color: "white !important",
                    "&:active": {
                        background: "red"
                    }
                    , "&.Mui-disabled": {
                        opacity: .7


                    }


                },
            },
        },

        MuiCheckbox: {
            defaultProps: {

                icon: <CircleOutlinedIcon fontSize='medium' />,
                indeterminateIcon: <CheckCircleIcon fontSize='medium' />,
                checkedIcon: <CheckCircleIcon fontSize='medium' sx={{ boxShadow: '0 0 9px blue', }} />


            }



        },

    },


});




export const WebsiteTheme = (dir) => createTheme({





    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#0f0f53',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#c98928',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        customA: {
            light: '#ffa726',
            main: "#FFFFFF",
            dark: '#c1c1d7',
            contrastText: 'rgba(0, 0, 0, 0.87)',
        }

    },


    direction: dir,



    components: {
        // Name of the component











        MuiButton: {
            styleOverrides: {
                // Name of the slot
                contained: {

                    background: dir === "rtl" ? "linear-gradient(12deg, rgba(67,100,175,1) 100%, rgba(79,171,217,1) 100%) !important" : "linear-gradient(12deg, rgba(67,100,175,1) 0%, rgba(79,171,217,1) 100%)!important",
                    width: '100%',
                    color: "white !important",
                    "&:active": {
                        background: "red"
                    }
                    , "&.Mui-disabled": {
                        opacity: .7


                    }


                },
            },
        },

        MuiTextField: {
            styleOverrides: {

                root: {

                    borderRadius: "7px",






                }
            }

        },

        MuiCheckbox: {
            defaultProps: {

                icon: <CircleOutlinedIcon fontSize='medium' />,
                indeterminateIcon: <CheckCircleIcon color='#FFFFF' fontSize='medium' />,
                checkedIcon: <CheckCircleIcon color='#FFFFF' fontSize='medium' />






            },


            variants: [
                {
                    props: { variant: 'headCell' }
                    ,
                    style: {
                        color: "#FFFFFF",

                    },
                }
            ],



        },

        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(67,100,175,1)"
                }

            }



        },

        MuiTableCell: {
            styleOverrides: {

                head: sx({

                    color: "#FFFFFF",
                    top: 0,
                    position: 'sticky',
                    zIndex: 2,
                    display: "table-cell",
                    backgroundColor: "rgba(67,100,175,1)",
                    vertical: "vertical",
                    padding: "16px",
                    textAlign: 'start',
                    fontSize: {
                        xs: "0.9rem",
                        md: "1.2rem"

                    }

                }),
                body: sx({


                    textAlign: 'start',
                    fontSize: {
                        xs: "0.9rem",
                        md: "1rem"

                    }

                }),

            }
        },

        MuiTypography: {
            styleOverrides: {
                h1: {

                    fontFamily: dir === 'rtl' && 'ArabicBlack',

                    fontSize: dir === 'rtl' && { xs: "1.5rem" },

                },

                h2: {

                    fontFamily: dir === 'rtl' && 'ArabicBlack',



                    '@media (max-width:600px)': {
                        fontSize: dir === 'rtl' && "1.2rem"
                    },


                    '@media (min-width:600px)': {
                        fontSize: dir === 'rtl' && "1.6rem"
                    },

                },


                h3: {

                    fontFamily: dir === 'rtl' && 'ArabicBlack'


                },


                h4: {

                    fontFamily: dir === 'rtl' && 'ArabicBold'


                },


                h5: {

                    fontFamily: dir === 'rtl' && 'ArabicRegualr'


                },


                h6: {

                    fontFamily: dir === 'rtl' && 'ArabicRegualr'


                },

                subtitle1: {
                    fontFamily: dir === 'rtl' && 'ArabicRegualr'




                },

                subtitle2: {
                    fontFamily: dir === 'rtl' && 'ArabicRegualr'




                },


                body1: {
                    fontFamily: dir === 'rtl' && 'ArabicRegualr'




                },



                body2: {
                    fontFamily: dir === 'rtl' && 'ArabicRegualr'




                },


            }





        }
    },



});